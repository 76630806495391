import React from 'react';
import { FormLabel, Input } from '@uikit';
import { InputProps } from '../types';

interface EmailInputProps<T> extends InputProps<T> {}

export const formatEmail = (val) => {
  return val;
};

// stores the actual value as email
export const parseEmail = (val) => {
  return val?.replace(' ', ''); // removes any white space
};

const EmailInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value,
      label,
      sublabel,
      help,
      optional,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      error,
    }: EmailInputProps<T>,
    ref,
  ) => {
    return (
      <>
        <FormLabel
          testID={testID}
          label={label || 'Email address'}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        <Input
          ref={ref}
          testID={testID}
          disabled={disabled}
          autoFocus={autoFocus}
          keyboard="email"
          name={name}
          value={value}
          onChange={(val) => onChange(parseEmail(val))}
          onBlur={onBlur}
          placeholder="Email address"
          error={error}
          fullWidth
        />
      </>
    );
  },
);

EmailInput.displayName = 'EmailInput';
export default EmailInput;
