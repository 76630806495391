import React from 'react';
import { Stack } from '@layouts';
import { Radio } from '@uikit';
import { Option } from '@types';
import { InputProps } from '../types';
import Fieldset from './FieldsetInput';

interface RadioInputProps<T> extends InputProps<T> {
  options: Array<Option>;
  horizontal?: boolean;
  error?: string;
}

const RadioInput = <T extends any>({
  testID,
  name,
  label,
  sublabel,
  help,
  optional,
  value,
  onChange,
  onFocus,
  onBlur,
  options,
  disabled,
  horizontal,
  error,
  ...props
}: RadioInputProps<T>) => {
  return (
    <Stack spacing="0b">
      {/** Fieldset renders the FormLabel <label> as <legend> */}
      <Fieldset
        testID={testID}
        label={label}
        sublabel={sublabel}
        name={name}
        help={help}
        optional={optional}
        error={error}
        {...props}
      >
        <Stack
          horizontal={horizontal}
          itemStyle={horizontal ? { flex: 1 } : undefined}
          spacing="0b"
        >
          {options.map((option) => (
            <Radio
              key={option.value}
              testID={`${testID || name}-${option.value}`}
              name={name}
              label={option.label}
              value={option.value}
              checked={value === option.value}
              disabled={disabled}
              error={error}
              onFocus={onFocus}
              onChange={(val) => {
                onChange(val);
                onBlur();
              }}
            />
          ))}
        </Stack>
      </Fieldset>
    </Stack>
  );
};

export default RadioInput;
