import React, { useEffect, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Asset, Button, FormLabel } from '@uikit';
import { Card, Stack } from '@layouts';
import { View } from 'react-native';
import FieldElement from './FieldElement';
import { useDependentValues } from '../utils/useDependentValues';
import Close from '@svg/close.svg';

const FieldArrayItem = ({
  prefix,
  form,
  subfield,
  isFirst,
  isLast,
  grouped,
  values,
  remove,
  ariaContext,
}) => {
  const element = useMemo(() => {
    return {
      ...subfield,
      prefix,
      dependencies: (subfield?.dependencies || []).map((d) => `${prefix}.${d}`),
      name: `${prefix}.${subfield.name}`,
      testID: `${prefix}.${subfield.testID || subfield.name}`,
    };
  }, [subfield, prefix]);

  return (
    <FieldElement
      isFirst={isFirst}
      isLast={isLast}
      form={form}
      prefix={prefix}
      element={element}
      grouped={grouped}
      values={values}
      remove={remove}
      ariaContext={ariaContext}
    />
  );
};

/**
 * Renders the container
 */
const FieldArrayContainer = ({
  field,
  form,
  name,
  subfields,
  dependencies,
  index,
  isFirst,
  isLast,
  grouped,
  Component,
  remove,
  contextKey,
}) => {
  const dependentValues = useDependentValues({
    dependencies,
    control: form.methods.control,
    prefix: `${name}.${index}`,
  });

  /** Used to create contextual aria labels to differentiate grouped inputs */
  const ariaContext = dependentValues[contextKey];

  const formFields = subfields.map((subfield, i) => (
    <FieldArrayItem
      key={`${name}-${subfield.name}`}
      prefix={`${name}.${index}`}
      form={form}
      subfield={subfield}
      isFirst={isFirst && i === 0}
      isLast={false}
      grouped={grouped}
      values={dependentValues}
      remove={() => remove(index)}
      ariaContext={ariaContext}
    />
  ));

  if (Component) {
    return (
      <Component values={dependentValues} remove={() => remove(index)}>
        {formFields}
      </Component>
    );
  }
  return (
    <Card key={field.id} border containerStyle={{ marginBottom: 24 }}>
      <View style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <Asset
          containerSize="xs"
          size="mini"
          svg={Close}
          bg="skeleton"
          bgHover="border"
          shape="circle"
          onPress={() => remove(index)}
        />
      </View>
      <View style={{ paddingTop: 16 }}>{formFields}</View>
    </Card>
  );
};
/**
 * Renders the footer
 * - Pass through append to add to the array
 */
const FieldArrayFooter = ({ dependencies, form, append, Footer, isFirst, isLast, disabled }) => {
  const dependentValues = useDependentValues({
    dependencies,
    control: form.methods.control,
  });

  if (Footer) {
    return (
      <Footer
        disabled={disabled}
        values={dependentValues}
        append={append}
        isFirst={isFirst}
        isLast={isLast}
      />
    );
  }
  return (
    <Button testID="add" onPress={() => append({})} disabled={disabled}>
      Add
    </Button>
  );
};

const FieldArray = ({
  name,
  label,
  sublabel,
  help,
  optional,
  required,
  grouped,
  subfields,
  dependencies,
  form,
  isFirst,
  isLast,
  disabled,
  Component,
  Footer,
  contextKey,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: form.methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name, // unique name for your Field Array
  });

  /**
   * @workaround
   * ideally, the rules property on field array would work
   * since it doesn't, we just set and clear the error manually
   */
  useEffect(() => {
    if (required) {
      if (fields?.length === 0) {
        form.methods.setError(name, { type: 'custom', message: 'Required' }); // set the error
      } else {
        form.methods.clearErrors(name);
      }
    }
    return () => {
      form.methods.clearErrors(name);
    };
  }, [fields, required]);

  return (
    <Stack spacing="1">
      <FormLabel section={true} label={label} sublabel={sublabel} help={help} optional={optional} />
      {fields.map((field, index) => (
        <FieldArrayContainer
          key={`${name}-${index}`}
          field={field}
          index={index}
          form={form}
          subfields={subfields}
          dependencies={dependencies}
          name={name}
          isFirst={isFirst}
          isLast={isLast}
          grouped={grouped}
          Component={Component}
          remove={remove}
          contextKey={contextKey}
        />
      ))}
      <FieldArrayFooter
        name={name}
        form={form}
        dependencies={dependencies}
        append={append}
        Footer={Footer}
        isFirst={isFirst}
        isLast={isLast}
        disabled={disabled}
      />
    </Stack>
  );
};
export default FieldArray;
