import React from 'react';
import styled from 'styled-components';
import { FormLabel } from '@uikit';
import { InputProps } from '../types';

interface TextareaInputProps<T> extends InputProps<T> {
  placeholder?: string;
  rows?: number;
  readOnly?: boolean;
}

interface StyledTextareaContainerProps {
  readOnly?: boolean;
}

const StyledTextareaContainer = styled.div<StyledTextareaContainerProps>`
  position: relative;
  box-sizing: border-box;

  ${({ readOnly }) =>
    readOnly &&
    `
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background: linear-gradient(transparent, rgba(255, 255, 255, 0.95));
      pointer-events: none;
    }
  `}
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  border: 1.5px solid transparent;
  box-sizing: border-box;
  padding: 0;
  resize: none;
  font-size: inherit;
  font-family: inherit;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  position: relative;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: 1.5px solid ${(p) => p.theme.colors.fg};
  }

  ${({ readOnly }) =>
    readOnly &&
    `
    cursor: not-allowed;
    font-weight: 500;
  `}
`;

const TextareaInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value,
      label,
      sublabel,
      optional,
      placeholder,
      disabled,
      readOnly,
      onChange,
      onFocus,
      onBlur,
      error,
      help,
      rows,
    }: TextareaInputProps<T>,
    ref: React.Ref<any>,
  ) => {
    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        <StyledTextareaContainer readOnly={readOnly}>
          <StyledTextarea
            ref={ref}
            data-testid={testID}
            autoFocus={autoFocus}
            name={name}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            onChange={(e) => onChange(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            rows={rows}
          />
        </StyledTextareaContainer>
      </>
    );
  },
);

TextareaInput.displayName = 'TextareaInput';
export default TextareaInput;
