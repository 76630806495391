import React from 'react';
import { Copy, FontSize, FontWeight } from '@types';
import { Stack } from '@layouts';
import Text from './Text';
import HelpText from './HelpText';
import TestID from './TestID';
import { useCopy } from '@app/utils';

export interface FormLabelProps {
  testID?: string;
  label?: Copy;
  sublabel?: Copy;
  help?: Copy;
  optional: boolean;
  asLegend?: boolean; // Fieldset elements need a nested <legend> tag
  ariaLabel?: string;
}

export const FormLabel: React.FC<FormLabelProps> = ({
  testID,
  label,
  sublabel,
  help,
  optional,
  asLegend = false,
  ariaLabel,
}) => {
  const { c } = useCopy('catch');
  if (!label && !help) return null;

  const content = (
    <Stack horizontal flexItem={0} style={{ paddingBottom: 8 }}>
      <Stack>
        {!!optional && (
          <Text size={FontSize.fp} color="subtle" gutter="bottom">
            {c('optional')}
          </Text>
        )}
        {!!label && (
          <Text
            testID={`${testID}-label`}
            size={asLegend ? undefined : FontSize.label} // FontSize.label forces <label> tag
            weight={FontWeight.medium}
          >
            {label}
            <TestID>{testID}</TestID>
          </Text>
        )}
        {!!sublabel && (
          <Text size={FontSize.fp} color="subtle">
            {sublabel}
          </Text>
        )}
      </Stack>
      {!!help && <HelpText content={help} label={label} />}
    </Stack>
  );

  /** Fieldset requires a <legend> element instead of a label */
  if (asLegend) {
    return (
      <legend id={`${testID}-label`} aria-label={ariaLabel}>
        {content}
      </legend>
    );
  }

  return (
    <label id={`${testID}-label`} htmlFor={testID} aria-label={ariaLabel}>
      {content}
    </label>
  );
};

export default FormLabel;
