import React, { useRef } from 'react';
import { MultipleFieldErrors } from 'react-hook-form';
import { View } from 'react-native';
import { FormLabel, Dropdown, Input } from '@uikit';
import { stateItems } from '@app/utils'; // should be moved to a config/global
import { InputProps } from '../types';
import { Stack } from '@app/layouts';

interface AddressInputProps<T> extends InputProps<T> {
  errors?: MultipleFieldErrors;
  disabledFields?: {
    street1?: boolean;
    street2?: boolean;
    city?: boolean;
    state?: boolean;
    zip?: boolean;
  };
}

const parseZip = (val: string): string => {
  return val?.replace(/\D+/g, '').slice(0, 5);
};

const AddressInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value, // to allow for easy on change
      label,
      sublabel,
      help,
      optional,
      disabled,
      disabledFields,
      onChange,
      onBlur,
      errors,
    }: AddressInputProps<T>,
    ref,
  ) => {
    const inputRefs = useRef([]);

    return (
      <>
        <FormLabel label={label} sublabel={sublabel} help={help} optional={optional} />
        <Stack spacing="0b">
          <Input
            ref={ref}
            testID={`${testID}-street1`}
            disabled={!!disabled || !!disabledFields?.street1}
            autoFocus={autoFocus}
            keyboard="addressLine1"
            value={value?.street1}
            onChange={(val) => onChange({ ...value, street1: val })}
            onBlur={onBlur}
            placeholder="Street address"
            fullWidth
            error={errors?.street1}
          />
          <Input
            ref={(el) => (inputRefs.current['street2'] = el)}
            testID={`${testID}-street2`}
            disabled={!!disabled || !!disabledFields?.street2}
            keyboard="addressLine2"
            value={value?.street2}
            onChange={(val) => onChange({ ...value, street2: val })}
            onBlur={onBlur}
            placeholder="Apartment, suite, building #"
            fullWidth
            error={errors?.street2}
          />
          <Input
            ref={(el) => (inputRefs.current['city'] = el)}
            testID={`${testID}-city`}
            disabled={!!disabled || !!disabledFields?.city}
            keyboard="city"
            value={value?.city}
            onChange={(val) => onChange({ ...value, city: val })}
            onBlur={onBlur}
            placeholder="City"
            fullWidth
            error={errors?.city}
          />
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 3 }}>
              <Dropdown
                ref={(el) => (inputRefs.current['state'] = el)}
                testID={`${testID}-state`}
                disabled={!!disabled || !!disabledFields?.state}
                keyboard="state"
                name={name}
                value={value?.state}
                options={stateItems}
                placeholder="State"
                onChange={(state) => onChange({ ...value, state })}
                fullWidth
                error={errors?.state}
              />
            </View>
            <View style={{ flex: 2, marginLeft: 8 }}>
              <Input
                ref={(el) => (inputRefs.current['zip'] = el)}
                testID={`${testID}-zip`}
                disabled={!!disabled || !!disabledFields?.zip}
                keyboard="zip"
                value={value?.zip}
                onChange={(val) => onChange({ ...value, zip: parseZip(val) })}
                onBlur={onBlur}
                placeholder="Zip code"
                fullWidth
                error={errors?.zip}
              />
            </View>
          </View>
        </Stack>
      </>
    );
  },
);

AddressInput.displayName = 'AddressInput';
export default AddressInput;
