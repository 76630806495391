import React from 'react';
import { Copy, NumericalType } from '@types';
import { FormLabel, Input } from '@uikit';
import { InputProps } from '../types';
import { copy } from '@app/utils';

interface NumberInputProps<T> extends InputProps<T> {
  numberType: NumericalType;
  placeholder: Copy;
  ariaLabel?: string;
}

// shows a formatted value like $ 25,000
export const formatNumber = (val, numberType: NumericalType) => {
  if (val === 0) {
    return '0';
  }

  if (val) {
    if (numberType === 'decimal') {
      return copy.num(val);
    }

    const num = parseInt(`${val}`.replace(/\D+/g, ''), 10);
    return num.toLocaleString('en-US');
  }

  return val || '';
};

// stores the actual value as a base-10 integer
export const parseNumber = (val, numberType: NumericalType) => {
  const digits = val?.replace(/\D+/g, '');

  if (digits) {
    if (numberType === 'decimal') {
      return parseFloat(`${digits / 100}`);
    }

    return parseInt(digits, 10);
  } else {
    return '';
  }
};

const NumberInput = <T extends any>({
  testID,
  autoFocus,
  numberType,
  placeholder,
  name,
  value,
  label,
  sublabel,
  help,
  optional,
  disabled,
  onChange,
  onBlur,
  onKeyPress,
  error,
  ariaLabel,
}: NumberInputProps<T>) => {
  return (
    <>
      <FormLabel
        testID={testID}
        label={label}
        sublabel={sublabel}
        help={help}
        optional={optional}
        ariaLabel={ariaLabel}
      />
      <Input
        testID={testID}
        id={testID}
        autoFocus={autoFocus}
        name={name}
        keyboard="number"
        value={formatNumber(value, numberType)}
        onChange={(val) => onChange(parseNumber(val, numberType))}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        fullWidth
      />
    </>
  );
};

export default NumberInput;
