import React, { useRef } from 'react';
import { MultipleFieldErrors } from 'react-hook-form';
import { NameSubfields } from '@types';
import { FormLabel, Dropdown, Input } from '@uikit';
import { Stack } from '@layouts';
import { InputProps } from '../types';
import { optionPresets } from '../config/options';
import { useCopy } from '@app/utils';

interface LegalNameInputProps<T> extends InputProps<T> {
  errors?: MultipleFieldErrors;
  subfields: NameSubfields;
}

const LegalNameInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value, // to allow for easy on change
      label,
      sublabel,
      help,
      optional,
      subfields,
      disabled,
      onChange,
      onBlur,
      errors,
    }: LegalNameInputProps<T>,
    ref,
  ) => {
    const inputRefs = useRef([]);

    const { c } = useCopy('catch.basics');
    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        <Stack spacing="0b">
          {subfields.givenName && (
            <Input
              ref={ref}
              testID={`${testID}-givenName`}
              disabled={disabled}
              autoFocus={autoFocus}
              keyboard="firstName"
              value={value?.givenName}
              onChange={(givenName) => onChange({ ...value, givenName })}
              placeholder={c('firstName')}
              fullWidth
              error={errors?.givenName}
            />
          )}
          {subfields.middleName && (
            <Input
              ref={(el) => (inputRefs.current['middleName'] = el)}
              testID={`${testID}-middleName`}
              disabled={disabled}
              keyboard="middleName"
              value={value?.middleName}
              onChange={(middleName) => onChange({ ...value, middleName })}
              placeholder={c('middleName')}
              fullWidth
              error={errors?.middleName}
            />
          )}
          {subfields.familyName && (
            <Input
              ref={(el) => (inputRefs.current['familyName'] = el)}
              testID={`${testID}-familyName`}
              disabled={disabled}
              keyboard="lastName"
              value={value?.familyName}
              onChange={(familyName) => onChange({ ...value, familyName })}
              onBlur={onBlur} // this will trigger validation
              placeholder={c('lastName')}
              fullWidth
              error={errors?.familyName}
            />
          )}
          {subfields.nameSuffix && (
            <Dropdown
              ref={(el) => (inputRefs.current['nameSuffix'] = el)}
              testID={`${testID}-nameSuffix`}
              disabled={disabled}
              keyboard="suffix"
              placeholder="Suffix"
              name={name}
              value={value?.nameSuffix}
              options={optionPresets.SUFFIXES}
              onChange={(nameSuffix) => onChange({ ...value, nameSuffix })}
              fullWidth
              error={errors?.nameSuffix}
            />
          )}
        </Stack>
      </>
    );
  },
);

LegalNameInput.displayName = 'LegalNameInput';
export default LegalNameInput;
